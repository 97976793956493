import Swiper from "swiper/bundle"
import "swiper/css/bundle"

export function initializeSwiper() {
  const swiperContainers = document.querySelectorAll(".swiper-section")

  swiperContainers.forEach((container) => {
    new Swiper(container, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 30,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      a11y: {
        enabled: true,
        prevSlideMessage: "Previous slide",
        nextSlideMessage: "Next slide",
        paginationBulletMessage: "Go to slide {{index}}",
        slideLabelMessage: "Slide {{index}} of {{slidesLength}}",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    })
  })
}
